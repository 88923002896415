import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import questions from '../../data/matrixQuestions.json';
import categories from '../../data/matrixCat.json';

export default function ImportantIssuesAcc({matrixQuestions, office}) {
    const questionsFiltered = office === "AG" ? questions.filter(question => question.forAG === true) : questions.filter(question => question.short !== 'extraJudicial' );

    const accordingBgColor = [
        "#FBDFC2",
        "#D1E6C9",
        "#F0C0C1",
        "#A594CC",
        "#FFFDC1",
        "#beecbf",
        "#A2C1FF",
        "#FDA5A7",
        "#d4beec",
        "#f2d3e2",
        "#FC8709"
    ];

  return (
    <div>
        { categories.map( (category, index) => (
            <Accordion key={category.short} sx={{ mb: 1, backgroundColor: accordingBgColor[index], color: 'black' }}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                >
                <Typography sx={{ fontWeight: 'bold'}} >{category.long}</Typography>
                </AccordionSummary>                
                <AccordionDetails>
                    { questionsFiltered.filter(question => question.category === category.short).map( (oneQuestion) => (
                    <Box key={oneQuestion.short} mb={2} sx={{textAlign: 'left'}}>
                        <Typography>{oneQuestion.long}</Typography>
                        { matrixQuestions ?  
                            <Typography sx={{ fontWeight: 'bold'}}>
                                Answer: {matrixQuestions[oneQuestion.category][oneQuestion.short]} 
                            </Typography>
                        :                        
                            <Typography sx={{ fontWeight: 'bold'}}>
                                Answer: N.A
                            </Typography>
                        }
                    </Box>
                ) )}
                </AccordionDetails>
          </Accordion>
        ))}

      
    </div>
  );
}

ImportantIssuesAcc.propTypes = { 
    office: PropTypes.string.isRequired,
    matrixQuestions: PropTypes.shape({
        laborRights: PropTypes.shape({
            workerProtection: PropTypes.string,
            childcare: PropTypes.string,
            minWageIncrease: PropTypes.string,
        }),
        criminalJustice: PropTypes.shape({      
            policingBudget: PropTypes.string,
            demilPoliceForce: PropTypes.string,
            mandMin: PropTypes.string,
            legalDrug: PropTypes.string,            
        }),
        education: PropTypes.shape({
            diversity: PropTypes.string,
            sexEd: PropTypes.string,  
        }),
        electionReg: PropTypes.shape({
            voterProtect: PropTypes.string,
            campaignFin: PropTypes.string,
        }),
        environment: PropTypes.shape({            
            fossilFuel: PropTypes.string,
        }),
        healthcare: PropTypes.shape({
            roeWade: PropTypes.string,             
            mediAll: PropTypes.string, 
        }),
        immigration: PropTypes.shape({
            citizenship: PropTypes.string,            
        }),
        housingJustice: PropTypes.shape({           
            publicHousing: PropTypes.string,
        }),        
        economy: PropTypes.shape({
            wealthTax: PropTypes.string,            
        }),        
    }).isRequired
}