import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
// import Link from '@mui/material/Link';

export default function MapHomepage() {

    const navigate = useNavigate();
    /*
    const [listOfStates, setListOfStates] = useState();
    

    const getStates = async () => {
        const response = await fetch(`/api/dropdown`);
        const result = await response.json();                   
        if (result.success) {
            const results = result.data.filter(oneState => oneState.stateActive === true);
            setListOfStates(results);
        }    
        
    }
    
    useEffect( async () => {
        getStates()
        
    }, []);
    */
    const keyStates = [
        {
            name: "Wisconsin",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/1.png?alt=media&token=52e0ee40-ad92-43f7-84e8-89c2c835c24d"
        },
        {
            name: "Ohio",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/2.png?alt=media&token=04d436b3-2eba-4e14-8ee5-504ae2047245"
        },
        {
            name: "Pennsylvania",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/3.png?alt=media&token=69e8eea4-26cf-4bdb-b221-488950d10da1"
        },
        {
            name: "Georgia",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/5.png?alt=media&token=4de2efac-1a88-4013-b65c-3155599f4055"
        },
        {
            name: "New York",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/6.png?alt=media&token=dbc0ed65-6b43-4eed-a792-ea92c8da5861"
        },
        {
            name: "Arizona",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/7.png?alt=media&token=fe693e14-716b-4abf-96d5-e38215a8780d"
        }
    ]

    return (
        <Container>
            { /*
        <Typography variant="h4" my={2} color="primary">Click on your state to view your candidates who will be on your midterm ballot!</Typography>
        */ }
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Typography color="primary" sx={{ p: 2, width: "80%", border: "1px solid #9e522a", borderRadius: "10px" }}>
                    Welcome to thedownballot.org! We&apos;re thrilled you&apos;re here. We&apos;re preparing for the 2024 General Election, launching on October 22nd. Since we&apos;re a small team, we&apos;ll cover the states listed below but aim to expand nationwide in the future. Follow us on Instagram and TikTok for updates!
                </Typography>
            </Box>

                <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2, ml: 9 }}>
                    {keyStates.map((oneMap) => (
                        <Grid item xs={4} key={oneMap.id} onClick={() => navigate("/addressPage")}>
                            <Box
                                component="div"
                            
                                sx={{
                                    my: 2,
                                    minWidth: '80px',
                                    maxWidth: '200px',
                                    backgroundColor: '#9e522a', 
                                    "&:hover": { cursor: "pointer" } 
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        width: '100%'
                                    }}
                                    src={oneMap.image}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>


            
            { /*
        <Box sx={{display: "flex", justifyContent: "center", mt: 3}}>
            <Typography color="primary" sx={{ p: 2, width: "80%", border: "1px solid #9e522a", borderRadius: "10px"}}>As a small organization, we chose to focus our coverage on a few states and key races across the country. 
            However, our friends at <Link href="https://acti.vote/thedownballot">activote.net</Link> and <Link href="https://goodparty.org/">goodparty.org</Link> have nationwide coverage that we encourage you to check out! Simply click on their logos to navigate to their voting guides!</Typography>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{ mt: 2}}>
            <Grid item xs={6} md={3}>
                <Box 
                    component="div"
                    sx={{
                        my: 2,
                        minWidth: '80px',
                        maxWidth: '200px',
                        backgroundColor: '#9e522a'
                    }}
                >
                    <Box 
                        component='img'
                        sx={{
                            width: '80%',
                            p: 2
                        }}
                        src="/mapsLanding/goodparty.png" />
                    
                    <Box component="div" sx={{backgroundColor: '#9e522a'}}>
                        <Button 
                        variant="outlined" 
                        href="https://goodparty.org"
                        sx={{borderRadius: "10px", mb: 2, backgroundColor: "white", "&:hover": {color: "white"}}}>
                            GOODPARTY.ORG</Button>
                    </Box>
                    
                </Box>
            </Grid>

            <Grid item xs={6} md={3}>
                <Box 
                    component="div"
                    sx={{
                        my: 2,
                        minWidth: '80px',
                        maxWidth: '200px',
                        backgroundColor: '#9e522a'
                    }}
                >
                    <Box 
                        component='img'
                        sx={{
                            width: '80%',
                            p: 2
                        }}
                        src="/mapsLanding/activote.png" />
                    
                    <Box component="div" sx={{backgroundColor: '#9e522a'}}>
                        <Button 
                        variant="outlined" 
                        href="https://acti.vote/thedownballot"
                        sx={{borderRadius: "10px", mb: 2, backgroundColor: "white", "&:hover": {color: "white"}}}>
                            ACTIVOTE</Button>
                    </Box>
                    
                </Box>
            </Grid>
        </Grid>   
        */}
        </Container>
    )
}
