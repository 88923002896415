import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '75%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: {xs: "75%", md: "500px"},
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const writeUp = {
    "House of Representatives": [
        `Members of the House of Representatives serve 2 year tenures as one of a handful of officials responsible for advocating for their state's constituents at the Capitol in Washington D.C. The members of the House per state vary based on the state's population size but there can be no more than 435 Representatives in office but DC statehood could increase this number to 436. All 435 seats in the House are up for re-election in 2022. House members are responsible for proposing, creating,  and voting on federal legislation that affects both domestic and foreign policy. Unlike members of the Senate, they can introduce impeachment proceedings but cannot conduct them.`,
        `According to the Pew Research Center,  71% of Republican voters in 2022 intend to vote for candidates that will allow for a Republican control of the House. Some of the key issues that are important to voters include tackling inflation, increasing the minimum wage, the adoption of universal childcare, strengthening the Affordable Care Act, providing equitable education funding, the passage of key criminal justice reforms and more.`,
    ],
    
    "Senate":[
        `Members of the Senate are appointed to represent their state's constituents at the Capitol in Washington DC. Every state is afforded two Senate representatives who are responsible for proposing, creating,  and voting on federal legislation that affects both domestic and foreign policy. Senators are also afforded the power to conduct impeachment proceedings and are key in the confirmation of Judges to the Supreme court and other judicial positions.`,
        `Some of the key issues at the forefront of the Senate race include tackling inflation, increasing the minimum wage, the adoption of universal childcare, strengthening the Affordable Care Act, providing equitable education funding, the passage of key criminal justice reforms and more.`
    ],

    "Governor": [
        `Governors fiunction as the head of the executive branch of the state legislature. They are responsible for passing legislation introduced by members of the state senate/assembly and also have the authority to veto legislation. They can also pass executive orders, deputize the National Guard under certain conditions, appoint certain politicians to office such as state judges, and pardon incarcerated constituents. Governors are elected every four years and, in some states, can only serve a limited amount of terms while other states have no term limits.`,
        `Some of the key issues that will a the forefront of gubernatorial races are 2nd amendment/gun ownership laws, election reform, abortion rights, the passage of bills banning the supposed teaching of Critical Race Theory (CRT) to grade school students, the participation of transgender students in high school sports, and the teaching of issues related to the LGBTQ+ community.`],
    
    "Attorney-General": [
        `Attorney Generals serve as the state's criminal prosecutors and oversee everything from civilian incarceration, police accountability, sex work decriminalization, immigration reform, and various other key legal  issues.`, 
        `The importance of electing a progressive Attorney General cannot be understated as they will directly impact criminal justice reform  from prosecuting cases of police brutality, litigating the legitimacy of cash bail, investigating cases of misconduct enacted by ICE within immigrant communities, protecting workers from wage theft and more.`
    ],
    
    "State Assembly" : [
        `Members of the State Assembly form part of the legislative branch of the State legislature. Assembly members pass laws, establish state budgets, such as those that go toward policing, education, infrastructure, and other public institutions. They also vote on various bills through committees that allow them to develop specialized knowledge over certain legislation. Politicians in the Assembly serve two-year terms without term limits.`,
        `Some of the key issues that will be consequential in this race include increasing  the minimum wage and other worker protections, the adoption of universal childcare, redistricting and gerrymandering, the defunding of oversized police budgets, providing equitable education funding, the passage of key criminal justice reforms and more.`
    ],

    "State Senate": [
        `Responsibilities: State Senators are appointed to represent their constituents by proposing, creating, and voting on legislation within the state legislature over the course of an unlimited amount of  four year terms.`,
        `Key Issues: State Senators have a key deciding effect on state budget expenditure, the acceptance of federal funding, and, with the fate of Roe v. Wade hanging in the balance, will play a key role in the administration of access to reproductive healthcare if abortion rights are left up to the states.`
    ],

    "State HOR" : [
        "Responsibilities: Members of the State Assembly form part of the legislative branch of the State legislature. Assembly members pass laws, establish state budgets, such as those that go toward policing, education, infrastructure, and other public institutions. They also vote on various bills through committees that allow them to develop specialized knowledge over certain legislation. Politicians in the Assembly serve two-year terms without term limits.", 
        "Key Issues: Some of the key issues that will be consequential in this race include increasing  the minimum wage and other worker protections, the adoption of universal childcare, redistricting and gerrymandering, the defunding of oversized police budgets, providing equitable education funding, the passage of key criminal justice reforms and more."
    ]
}

export default function OfficeDuties({title}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            {/* <Button variant="h2" color="primary" onClick={handleOpen}>{title}</Button> */}
            <Box component="div" sx={{display: 'flex', gap: "1px", alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h2" color="primary">{title}</Typography>
                <IconButton onClick={handleOpen}>
                    <InfoOutlinedIcon />
                </IconButton>
            </Box>
            <Modal
            open={open}
            onClose={handleClose}         
            >
            <Box sx={style}>
                <Box component="div" sx={{p: 0, mb: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant="h6" component="h2">
                        {title}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ color:'grey'}}
                        >
                        <CloseIcon />
                    </IconButton>
                </Box>
                
                <Typography sx={{ mt: 2 }}>
                 <b>Responsibilities: </b>{writeUp[title][0]}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                <b>Key Issues: </b>{writeUp[title][1]}
                </Typography>
            </Box>
            </Modal>
        </div>
    )
}

OfficeDuties.propTypes = {
    title: PropTypes.string.isRequired,        
  }