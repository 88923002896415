import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
// import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import AddEditCandidate from './AddEditCandidate'
import DeleteCandidate from './DeleteCandidate'
import UpdateDropdown from './UpdateDropdown'
import { stateFull } from '../../utils/states'

export default function CandidateAdminPage() {
    const [officeRunning, setOfficeRunning] = useState(
        'House of Representatives'
    )
    const [rowsOfData, setRowsOfData] = useState([])
    const [usState, setUsState] = useState('Alabama')
    const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
    const [openAlertFailure, setOpenAlertFailure] = useState(false)
    const [loading, setLoading] = useState(false)

    const initialValue = {
        fullName: '',
        firstName: '',
        lastName: '',
        party: '',
        incumbent: '',
        district: '',
        headshot: '',
        bio: '',
        polLeaning: '',
        votingRecord: false,
        office: '',
        specialNotes: '',
        sources: [],
        matrixQuestions: {
            laborRights: {
                workerProtection: '',
                childcare: '',
                minWageIncrease: '',
            },
            criminalJustice: {
                policingBudget: '',
                demilPoliceForce: '',
                mandMin: '',
                legalDrug: '',                
            },
            education: {
                diversity: '',
                sexEd: '',
            },
            electionReg: {
                voterProtect: '',
                campaignFin: '',
            },
            environment: {                
                fossilFuel: '',
            },
            healthcare: {
                roeWade: '',                
                mediAll: '',
            },
            immigration: {
                citizenship: '',                
            },
            housingJustice: {                
                publicHousing: '',
            },            
            economy: {
                wealthTax: '',
            },            
        },
    }

    const tableHeaders = [
        'Full Name',
        'First Name',
        'Last Name',
        'Party',
        'Incumbent',
        'District',
        'Headshot',
        'Bio',
        'Pol Leaning',
        'Office',
        'Edit',
        'Delete',
    ]

    const headShotStyle = {
        width: '50px',
    }

    const handleChangeOffice = (event) => {
        setOfficeRunning(event.target.value)
    }

    const handleUsState = (event) => {
        setUsState(event.target.value)
    }

    const getCandidates = async (office) => {
        setRowsOfData([])
        setLoading(true)
        const response = await fetch(
            `/api/newCandidate?office=${office}&state=${usState}`
        )
        const data = await response.json()
        if (data.success) {
            setRowsOfData(data.data)
        }
        setLoading(false)
    }

    const removeCandidate = (id) => {
        const newRows = [...rowsOfData]
        setRowsOfData(newRows.filter((row) => row._id !== id))
    }

    const addCandidate = (newRow) => {
        setRowsOfData([...rowsOfData, newRow])
    }

    const editCandidate = (id, newData) => {
        const newRows = [...rowsOfData]
        const editedIndex = newRows.findIndex((row) => row._id === id)
        newRows[editedIndex] = newData
        setRowsOfData(newRows)
    }

    const handleCloseAlertSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenAlertSuccess(false)
    }

    const handleCloseAlertFailure = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenAlertFailure(false)
    }

    const handleAlert = (success = false) => {
        if (success === true) {
            console.log('Alert Success!')
            setOpenAlertSuccess(true)
            setOpenAlertFailure(false)
        } else {
            setOpenAlertSuccess(false)
            setOpenAlertFailure(true)
        }
    }

    useEffect(() => {
        getCandidates(officeRunning)
    }, [officeRunning, usState])

    return (
        <Container sx={{ p: 2, minHeight: '100vh', width: '90%' }}>
            <Snackbar
                open={openAlertSuccess}
                autoHideDuration={7000}
                onClose={handleCloseAlertSuccess}
            >
                <Alert
                    onClose={handleCloseAlertSuccess}
                    severity="success"
                    sx={{ width: '80%' }}
                >
                    Add/Edit of a candidate was successful!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openAlertFailure}
                autoHideDuration={7000}
                onClose={handleCloseAlertFailure}
            >
                <Alert
                    onClose={handleCloseAlertFailure}
                    severity="error"
                    sx={{ width: '80%' }}
                >
                    Add/Edit of a candidate failed!
                </Alert>
            </Snackbar>
            <Typography
                variant="h2"
                color="primary.main"
                sx={{ margin: '2rem 0 2rem 0px' }}
            >
                Candidate Dashboard
            </Typography>
            <UpdateDropdown />
            <AddEditCandidate
                row={initialValue}
                typeOfButton="Add"
                addCandidate={addCandidate}
                handleAlert={handleAlert}
                editCandidate={editCandidate}
            />
            <Box component="div" sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <FormControl>
                    <InputLabel id="officeId">Office</InputLabel>
                    <Select
                        labelId="officeId"
                        id="officeId1"
                        value={officeRunning}
                        label="office"
                        name="office"
                        onChange={handleChangeOffice}
                    >
                        <MenuItem value="House of Representatives">
                            House of Representatives
                        </MenuItem>
                        <MenuItem value="Senate">Senate</MenuItem>
                        <MenuItem value="Governor">Governor</MenuItem>
                        <MenuItem value="AG">Attorney-General</MenuItem>
                        <MenuItem value="State Senate">State Senate</MenuItem>
                        <MenuItem value="State HOR">State HOR</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="stateId">State</InputLabel>
                    <Select
                        labelId="stateId"
                        id="stateId1"
                        value={usState}
                        label="usState"
                        name="usState"
                        onChange={handleUsState}
                    >
                        {stateFull.map((oneState) => (
                            <MenuItem key={oneState} value={oneState}>
                                {oneState}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map((header) => (
                                <TableCell
                                    sx={{ fontWeight: 'bold' }}
                                    key={header}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow key="loading">
                                <TableCell colSpan={12}>Loading....</TableCell>
                            </TableRow>
                        )}
                        {rowsOfData.length > 0 &&
                            rowsOfData.map((row) => (
                                <TableRow
                                    key={row.fullName}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell>{row.fullName}</TableCell>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.party}</TableCell>
                                    <TableCell>{row.incumbent}</TableCell>
                                    <TableCell>{row.district}</TableCell>
                                    <TableCell>
                                        <Box
                                            component="img"
                                            sx={headShotStyle}
                                            src={
                                                row.headshot === null ||
                                                row.headshot === 'NA'
                                                    ? '/profile-placeholder.png'
                                                    : row.headshot
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {row.bio === 'NA' ||
                                        row.bio === null ? (
                                            <span>No Bio</span>
                                        ) : (
                                            <Link href={row.bio}>Bio</Link>
                                        )}
                                    </TableCell>
                                    <TableCell>{row.polLeaning}</TableCell>
                                    <TableCell>{row.office}</TableCell>
                                    <TableCell>
                                        <AddEditCandidate
                                            row={row}
                                            typeOfButton="Edit"
                                            addCandidate={addCandidate}
                                            handleAlert={handleAlert}
                                            editCandidate={editCandidate}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <DeleteCandidate
                                            id={row._id}
                                            removeCandidate={removeCandidate}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}
