import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import CandidateSection from './CandidateSection';

export default function CandidateResult({candidates}) {
    const [checked, setChecked] = useState(false);
    const [houseCandidates, senateCandidates, govCandidates, agCandidates, stateSenateCandidates, stateHORCandidates] = candidates;
    
    return (
        <div>
            <Typography variant="h1" my={2}>Candidate List</Typography>
            <CandidateSection heading="House of Representatives" candidates={houseCandidates}  />
            <CandidateSection heading="Senate" candidates={senateCandidates}  />
            <CandidateSection heading="Governor" candidates={govCandidates}  />
            <CandidateSection heading="Attorney-General" candidates={agCandidates} />
            <CandidateSection heading="State Senate" candidates={stateSenateCandidates} />
            <CandidateSection heading="State Assembly" candidates={stateHORCandidates} />   
            <Box component="div" 
            sx={{ position: 'fixed', bottom: 0, right: 0, p: 2, backgroundColor: "#f5f5f5", border: '1px solid black', cursor: 'pointer'}}
            onClick={ () => setChecked(!checked)}
            >
                <Collapse in={checked} collapsedSize={20}>
                    <Typography mb={2}>LEGEND</Typography>
                    <Typography align="left">Blue - Democrat</Typography>
                    <Typography align="left">Red - Republican</Typography>
                    <Typography align="left">Green - Progressive</Typography>
                    <Typography align="left">Brown - Independent</Typography>
                </Collapse>
            </Box>         
        </div>
    )
}

CandidateResult.propTypes = {
    candidates: PropTypes.instanceOf(Array).isRequired
}