const categories = [
    "As We Wait for the Revolution",
    "Conspiracy And Capitalism",
    "Current Events",
    "Deconstructing Narratives",
    "Dictatorship of the Proletariat",
    "Do Something About It",
    "I Dream of Socialism",
    "Midterms 2022",
    "Neoliberalism Explained",
    "Racism, Pride And Prejudice",
    "Right Wing Rhetoric",
    "So You Want to be a Capitalist?",
    "Spoils of the Imperial Core",
    "The Fetishization of Nonviolence",
    "The Follies of Capitalism",
    "Unionizing 101",
    "Why Voting Matters"
  ];

  export default categories;