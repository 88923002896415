/*
  Generalization of pages that inform with "Tiles".
*/

import React         from 'react';
import PropTypes     from 'prop-types';
import Typography    from "@mui/material/Typography";
import Box           from "@mui/material/Box";
import Button        from "@mui/material/Button";
import { useTheme }  from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import List          from "@mui/material/List";
import ListItem      from "@mui/material/ListItem";
import ListItemText  from "@mui/material/ListItemText";
import Link          from "@mui/material/Link";
import ArrowRight    from "../images/ArrowRight";

const m = {}; /* module */

/*
  Translate text marked with limited formatting, from a serializable
  notation, into React with Material UI and our theme.
*/

m.xlate = ctxt => input => Array.isArray(input) ?
  m.xlateArray(ctxt)(input) : input;
  /*
    If it isn't an array, it is a string, and React accepts strings to denote
    children. So just pass the string through, just as it is.
  */
m.xlateArray = ctxt => input => {
  let tag; /* tag type, e. g. 'p', 'div', 'span', or some others like HTML */
  let srcChildren;
  let attrs;
    /*
      We might pay attention to a few selected attributes, but maybe not all
      the ones HTML would. But for those we do use, we try to interpret them
      in as nearly the sense that HTML would as feasible.
      For example, for same meaning as
      <a href="https://example.com/foo">bar</a>,
      you can write ['a', {href: "https://example.com/foo"}, "bar"]
    */
  if (input.length < 1) throw Error("Expected a tag");
  if (
    input.length >= 2 && typeof input[1] === "object" &&
    ! Array.isArray(input[1])
  )
    [tag, attrs, ...srcChildren] = input;
  else {
    [tag, ...srcChildren] = input;
    attrs = {};
  };
  const hit = m.xlationTable[tag];
  if (! hit) throw Error(
    `Don't know how to translate tag ${JSON.stringify(tag)}.`
  );
  return hit.method(ctxt)({tag, attrs, srcChildren, tableEntry: hit})
};

m.std = ctxt => function std ({attrs, srcChildren, tableEntry}) {
  /* Standard translation */
  return React.createElement(
    tableEntry.type, attrs, ...srcChildren.map(m.xlate(ctxt))
  )
};
m.p = ctxt => function p ({srcChildren}) {
  /* Paragraph */
  return <Typography sx={ctxt.paragraphSx}>
    {srcChildren.map(m.xlate(ctxt))}
  </Typography>
};
m.ul = ctxt => function ul ({srcChildren}) {
  /* Unordered List */
  const revisedContext = {};
  Object.assign(revisedContext, ctxt);
  const listDepth = ctxt.listDepth || 0;
  const bulletType = listDepth > 0 ? "circle" : "disc";
    /*
      Note, spelled "disc", not "disk", despite what may seem normal to US
      speakers.
      https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type
      https://en.wiktionary.org/wiki/disk#Usage_notes
    */
  revisedContext.listDepth  = listDepth + 1;
  revisedContext.bulletType = bulletType;
  return <List dense sx={{pl: "2em", listStyleType: bulletType}}>
    {srcChildren.map(m.xlate(revisedContext))}
  </List>
};
m.li = ctxt => function li ({srcChildren}) {
  /* List Item */
  return < ListItem dense
    sx={{display: 'list-item', listStyleType: ctxt.bulletType}}
  > <ListItemText>{srcChildren.map(m.xlate(ctxt))}</ListItemText>
  </ListItem>
};

m.xlationTable = {
  div: {method: m.std, type: Box},
  p:   {method: m.p},
  ul:  {method: m.ul},
  li:  {method: m.li},
  a:   {method: m.std, type: Link}
};


const isScreenNarrow = () => {
  const screenIsWide = useMediaQuery(useTheme().breakpoints.up('sm'));
  return ! screenIsWide
};

/* Detail */
/* If the user clicks on a tile, we will show details. */
const DetailPropTypes = {
  parent: PropTypes.func.isRequired,  /* Ifc to next outer context.         */
  name: PropTypes.string.isRequired, /* Index string for which idea it is. */
};
const Detail = function Detail ({parent, name}) {
  const screenIsNarrow = isScreenNarrow();
  const paragraphSx = {lineHeight: "1.2", mt: ".5rem", mb: ".7rem"};
  paragraphSx.textAlign = screenIsNarrow ? "justify" : "left";
  const serDetailsBody = parent('serializableDetailsBodyForName', name);
  const detailsBody = m.xlate({paragraphSx})(serDetailsBody);
  const expansionTitle = <Typography variant="h4">
    {parent('expansionTitleForName', name)}
  </Typography>;
  const toggle = () => parent('toggle', name);
  const closeButtonMaybe = screenIsNarrow ? [] : < Button
    variant='outlined' onClick={toggle}
    sx={{borderRadius: "2rem"}}
  >X</Button>
  let next;
  let prev;
  if (! screenIsNarrow) {
    next = () => parent('next', name);
    prev = () => parent('prev', name);
  };
  const nextPrevButtonsMaybe = screenIsNarrow ? [] : <>
    <Button variant='outlined' onClick={prev} sx={{
      position: 'absolute', left: "1px", top: "1px",
      borderRadius: "2rem"
    }}>&lt;</Button>
    <Button variant='outlined' onClick={next} sx={{
      position: 'absolute', right: "1px", top: "1px",
      borderRadius: "2rem"
    }}>&gt;</Button>
  </>;
  const dontPropagate = event => event.stopPropagation();
  const innerBox = <Box onClick={dontPropagate} sx={{
    position: 'relative',
    border:       2,  /* px */
    borderColor:  "rgba(200, 103, 52, 0.31)",
    borderRadius: "10px",
    mx:           ".5rem",
    px:           ".5rem",
    backgroundColor: "#fff"
  }}>
    {closeButtonMaybe}
    {expansionTitle}
    {nextPrevButtonsMaybe}
    {detailsBody}
    {closeButtonMaybe}
  </Box>;
  return screenIsNarrow ? innerBox :
    < Box onClick={toggle} sx={{
      backgroundColor: "#eee",
      px:              "3rem",
      py:              "1rem"
    }}>{innerBox}</Box>
};
Detail.propTypes = DetailPropTypes;

/* Tile */
const TilePropTypes = {
  parent: PropTypes.func.isRequired,  /* Ifc to next outer context.         */
  name: PropTypes.string.isRequired, /* Index string for which idea it is. */
  overarches: PropTypes.bool,       /* Represents the overarching idea?   */
  extraMargin: PropTypes.bool
};
const TileDefaultProps = {overarches: false, extraMargin: false};
const Tile = function Tile ({parent, name, overarches, extraMargin}) {
  const screenIsNarrow = isScreenNarrow();
  const shallBeExpanded = name === parent('expandedItemName');
  const fontFamily = ({typography}) => typography.caption.fontFamily;
  const buttonSx = {fontFamily};
  Object.assign( buttonSx, screenIsNarrow ?
  {     /* bar */
    borderRadius:  ".8rem",
    py:            ".5rem",
    width:         "80%",
    mb:            ".8rem"
  } : { /* tile */
    borderRadius:  "20px",
    verticalAlign: "middle",
    width:         "289px",
    height:        "244px",
    px:            "1rem",
    mx:  extraMargin ? ".5rem" : 0,
    mb:            "15px",
  });
  if (overarches) buttonSx.backgroundColor = "secondary.main";
  const titleSx = {fontFamily, color: "white"};
  Object.assign(titleSx, screenIsNarrow ? {} : {
    fontSize:   "33px",
  });
  const buttonTitle = parent('tileTitleForName', name);
  const buttonText = screenIsNarrow ?
    `${buttonTitle} ${shallBeExpanded ? "▲" : "▼"}`
  : buttonTitle;
  const onClick = () => parent('toggle', name);
  const buttonKey = `${name}_button`;
  const titleKey = `${name}_title`;
  const button = < Button
    variant="contained" sx={buttonSx} onClick={onClick} key={buttonKey}
  >
    <Typography key={titleKey} sx={titleSx}>{buttonText}</Typography>
  </Button>;

  /*
    narrow screen, not expanded: just render the button.
    wide   screen, not expanded: just render the button.
    narrow screen,     expanded: render the button and then the expansion.
    wide   screen,     expanded: can't happen.
  */
  let r;
  if (shallBeExpanded && screenIsNarrow)
    r = <Box mb=".5rem">
      {button}
      <Detail parent={parent} name={name} />
    </Box>;
  else
    r = button;
  return r
};
Tile.propTypes    = TilePropTypes;
Tile.defaultProps = TileDefaultProps;

const optionalString = PropTypes.oneOfType([
  PropTypes.string, PropTypes.oneOf([null, undefined, false, 0])
]);

/* TilePage */
const TilePagePropTypes = {
  parent: PropTypes.func.isRequired, /* Ifc to next outer context. */
  titleText:    optionalString,      /* title for page header */
  subtitleText: optionalString,
  includeArrows: PropTypes.bool
};
const TilePageDefaultProps = {
  titleText: false, subtitleText: false, includeArrows: false
};
const TilePage = function TilePage ({
  parent, titleText, subtitleText, includeArrows
}) {
  const screenIsNarrow = isScreenNarrow();
  const overarchingIdeaName = parent('overarchingIdeaName');
  const subordinateIdeaNamesInOrder = parent('subordinateIdeaNamesInOrder');

  /*
    At most one of the tiles will be expanded at one time. Which one? We will
    use its name (a string  that can be used as an index) to  identify it. If
    none is open, we will give a falsy value.
  */
  const [expandedItemName, setExpandedItemName] = React.useState(null)

  let navMap;
  const navAround = (name, direction) => {
    if (! navMap) {
      const draft = {};
      const over = {name: overarchingIdeaName};
      over.next = over;
      over.prev = over;
      draft[over.name] = over;
      const append = aName => {
        const it = {name: aName};
        it.next = over;
        it.prev = over.prev;
        it.next.prev = it;
        it.prev.next = it;
        draft[it.name] = it
      };
      subordinateIdeaNamesInOrder.forEach(append);
      navMap = draft;
      Object.freeze(navMap)
    };
    setExpandedItemName(navMap[name][direction].name)
  };

  const servicesToChildren = {
    expandedItemName: () => expandedItemName,
    tileTitleForName:        parent,
    toggle:           (_, name) =>
      setExpandedItemName(expandedItemName === name ? null : name),
    expansionTitleForName:   parent,
    serializableDetailsBodyForName: parent,
    includeArrows:    () => includeArrows,
    next: (_verb, name) => navAround(name, 'next'),
    prev: (_verb, name) => navAround(name, 'prev')
  };
  const self = (verb, ...args) => {
    const hit = servicesToChildren[verb];
    if (! hit) throw new Error(
      `TilePage: unknown verb ${JSON.stringify(verb)}!`
    );
    return hit(verb, ...args)
  };

  /* Final result depends on an analysis into cases. */
  let r;
  if (screenIsNarrow)
    r = <Box component='div' key='whole_page'>
      <Tile
        parent={self} name={overarchingIdeaName} key={overarchingIdeaName}
        overarches
      />
      { subordinateIdeaNamesInOrder.map( name =>
        <Tile parent={self} name={name} key={name} />
      )}
    </Box>;
  else if (expandedItemName)
    r = < Detail parent={self} name={expandedItemName} key={expandedItemName}
    />;
  else {
    const subspex = subordinateIdeaNamesInOrder.map(name => ({name}));
    if (subspex.length >= 1) subspex[0].isFirst = true;
    const pageTitleHeader = titleText ?
      <Typography component="h1" variant="h2" my={2} key="page_title"
      >{titleText}</Typography>
    : [];
    const subtitle = subtitleText ?
      <Typography variant="subtitle" textAlign="center" key="subtitle">
        {subtitleText}
      </Typography>
    : [];
    r = <Box component='div' key="whole_page_wide_screen">
      {pageTitleHeader}
      {subtitle}
      <Box mt="1.5rem">
        <Tile
          parent={self} name={overarchingIdeaName}
          overarches      key={overarchingIdeaName}
        />
      </Box>
      <Box my="1.5rem" lineHeight={5} key='subordinate_tiles'>
        {subspex.map( spec => <Box component='span' key={`span_${spec.name}`}>
          {! spec.isFirst && includeArrows ?
            <ArrowRight key={`arrowBefore_${spec.name}`}/> : []
          }
          < Tile parent={self} name={spec.name} key={spec.name}
            extraMargin={! includeArrows}
          />
        </Box>)}
      </Box>
    </Box>;
  };
  return r
};
TilePage.propTypes    = TilePagePropTypes;
TilePage.defaultProps = TilePageDefaultProps;
export default TilePage
